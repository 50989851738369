<template>
   <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1L7.16667 6L1.33333 0.999999" stroke="#606F89" stroke-width="2" stroke-linecap="round" />
   </svg>
</template>

<script>
export default {
   name: 'IconDownArrow',
};
</script>
