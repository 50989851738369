<template>
   <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="17" fill="#E5EFFF" />
      <path d="M25 15L18 21L11 15" stroke="#137AF6" stroke-width="2" stroke-linecap="round" />
   </svg>
</template>

<script>
export default {
   name: 'IconDropdown',
};
</script>
