<template>
   <div class="dropdown" @mouseleave="hide">
      <button
         class="btn border"
         :class="{ 'dropdown-secondary': Secondary, 'dropdown-primary': Primary, 'bg-white': bgLight }"
         type="button"
         @click="showMenu"
      >
         <AmcText :name="inputName" class="d-flex justify-content-between">
            <AmcText class="d-flex align-self-center ms-3">
               <component :is="icon"></component>
               {{ inputName }}
            </AmcText>
            <IconDownArrow class="mt-2" v-if="Secondary" />
            <IconDropdown v-if="Primary" />
         </AmcText>
      </button>
      <ul
         v-if="show"
         @click="showMenu"
         class="amc-dropdown-menu mt-1 w-100"
         :class="{ 'border-primary rounded-8': Primary }"
      >
         <slot name="dropdown-items"></slot>
      </ul>
   </div>
</template>

<script>
import IconDownArrow from '@/components/icons/IconDownArrow';
import IconDropdown from '@/components/icons/IconDropdown';

export default {
   name: 'AmcSelectInput',
   components: {
      IconDownArrow,
      IconDropdown,
   },
   props: {
      icon: {
         type: [String, Object],
         default: '',
      },
      inputName: {
         type: [String, Number],
         default: '',
      },
      Secondary: {
         type: Boolean,
         default: false,
      },
      Primary: {
         type: Boolean,
         default: false,
      },
      bgLight: {
         type: Boolean,
         default: false,
      },
   },
   data() {
      return {
         show: false,
      };
   },
   methods: {
      showMenu() {
         this.show = !this.show;
      },
      hide() {
         setTimeout(() => {
            this.show = false;
         }, 200);
      },
   },
};
</script>

<style scoped lang="scss">
.dropdown {
   width: max-content;
   .dropdown-secondary {
      background-color: rgba(222, 220, 220, 0.41);
      color: #606f89;
      border: none;
      width: 150px;
      &::after {
         display: none;
      }
   }
   .amc-dropdown-menu {
      border: none;
      width: 150px;
      border-radius: 0 0 8px 8px;
      border-top: 3px solid gray;
      box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
   }
}
.dropdown {
   .dropdown-primary {
      background-color: white;
      border-radius: 100px;
      border: 1px solid #e1e1e1 !important;
      padding: 3px !important;
      color: #606f89;
      font-weight: 500;
      border: none;
      width: 150px;
      &::after {
         display: none;
      }
   }
}
.amc-dropdown-menu {
   z-index: 1000;
   position: absolute;
   min-width: 10rem;
   padding: 0.5rem 0;
   margin: 0;
   font-size: 1rem;
   color: #212529;
   text-align: left;
   list-style: none;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 0.25rem;
}
</style>
